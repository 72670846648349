import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html'
})
export class ApiErrorComponent {

  @Input() error: any;

  errors(): string[] {
    if (!!this.error?.error?.errors) {
      return Object.keys(this.error.error.errors).map(key => this.error.error.errors[key]);
    }

    if (!!this.error?.error) {
      return Object.keys(this.error.error).map(key => this.error.error[key]).flat();
    }

    return ['An unknown error has occurred'];
  }

  title(): string {
    if (!!this.error?.error) {
      return 'One or more validation errors occurred';
    }

    if (!!this.error.message) {
      return this.error.message;
    }

    return 'An unexpected error occurred';
  }

}
