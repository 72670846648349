import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'indicator',
  templateUrl: './indicator.component.html',
})
export class IndicatorComponent implements OnChanges {

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  public readonly isLoading$ = this._isLoading$.asObservable();

  @Input() loading: boolean | null = false;
  @Input() loadingText = 'Please wait...';

  ngOnChanges(changes: SimpleChanges): void {
    if ('loading' in changes) {
      this._isLoading$.next(changes['loading']?.currentValue);
    }
  }

}
