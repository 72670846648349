import { Component, Input } from '@angular/core';

@Component({
  selector: 'input-length',
  templateUrl: './input-length.component.html',
})
export class InputLengthComponent {

  @Input() minLength: number = 0;
  @Input() maxLength: number = 0;
  @Input() text: string = '';

}
