export interface ITinyMceSettings {
  plugins?: string;
  menubar?: boolean;
  outputFormat?: 'text' | 'html';
  setup?: (editor: any) => void;
  statusbar?: boolean;
  toolbar?: string;
}

export const TINYMCE_BASIC_SETTINGS: ITinyMceSettings = {
  plugins: 'lists link',
  menubar: false,
  statusbar: false,
  toolbar: 'undo redo | styles | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link'
}

