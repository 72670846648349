import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-busy',
  templateUrl: './busy.component.html'
})
export class BusyComponent {

  private timeout: any;

  transitioning = false;

  @Input() in = false;

  constructor(private cd: ChangeDetectorRef) { }

  private toggle(show: boolean): void {
    if (show === this.in) { return; }
    if (this.transitioning) {
      clearTimeout(this.timeout);
    }

    this.transitioning = true;
    this.in = show;
    this.cd.markForCheck();

    this.timeout = setTimeout(() => {
      this.transitioning = false;
      this.cd.markForCheck();
    }, 150);
  }

  hide(): void {
    this.toggle(false);
  }

  show(): void {
    this.toggle(true);
  }

}
