<div class="alert alert-danger" *ngIf="error">
  <h4 class="alert-heading">
    <i class="material-symbols-outlined">warning</i>
    {{title()}}
  </h4>

  <ul class="mb-0">
    <li *ngFor="let e of errors()">
      {{e}}
    </li>
  </ul>
</div>
