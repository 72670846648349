<div class="d-flex align-items-center">

  <div class="d-flex align-items-center justify-content-center justify-content-md-start">
    <label class="form-label mb-0" for="paginatorItems">Items to show</label>
    <select id="paginatorItems" class="form-select form-select-sm w-auto ms-3" [ngModel]="pageSize" (ngModelChange)="onPageSizeChange($event)">
      <option>10</option>
      <option>25</option>
      <option>50</option>
      <option>100</option>
      <option>200</option>
    </select>
  </div>

  <div class="d-flex align-items-center justify-content-center justify-content-md-end ms-auto">
    <ul class="pagination" *ngIf="!!data && data.totalPages > 1">
      <li class="page-item previous" [ngClass]="{'disabled': !data.hasPreviousPage}"><button (click)="onPrevious()" class="page-link"><i class="material-symbols-outlined">chevron_left</i></button></li>
      <li class="page-item" *ngFor="let page of pages$ | async" [ngClass]="{'active': page == data.pageNo}">
        <button class="page-link" (click)="onGoTo(page)">{{page}}</button>
      </li>
      <li class="page-item next" [ngClass]="{'disabled': !data.hasNextPage}"><button (click)="onNext()" class="page-link"><i class="material-symbols-outlined">chevron_right</i></button></li>
    </ul>
  </div>

</div>
