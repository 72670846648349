import { Component, HostBinding } from '@angular/core';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast-container',
  template: `
    <app-toast *ngFor="let toast of toastService.toasts" [toast]="toast" (hidden)="toastService.remove(toast)">
      {{toast.message}}
    </app-toast>
  `
})
export class ToastContainerComponent {

  constructor(public toastService: ToastService) { }

  @HostBinding('class') class = 'toast-container';

}
