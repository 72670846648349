import { Component } from '@angular/core';

@Component({
  templateUrl: './progress-modal.component.html',
})
export class ProgressModalComponent {

  public headerTitle: string = 'Please wait...';
  public message?: string;
}
