<div *ngIf="control?.errors?.['required']">{{label}} is required</div>
<div *ngIf="control?.errors?.['email']">{{label}} is not a valid email address</div>
<div *ngIf="control?.errors?.['emailUsed']">{{label}} is already in use</div>
<div *ngIf="control?.errors?.['itemCodeUsed']">{{label}} must be unique</div>
<div *ngIf="control?.errors?.['match']">{{label}} does not match</div>
<div *ngIf="control?.errors?.['max']">{{label}} must be no more than {{control.errors?.['max']['max']}}</div>
<div *ngIf="control?.errors?.['maxlength']">{{label}} must be less than {{control.errors?.['maxlength']['requiredLength']}} characters long</div>
<div *ngIf="control?.errors?.['min']">{{label}} must be at least {{control.errors?.['min']['min']}}</div>
<div *ngIf="control?.errors?.['minlength']">{{label}} must be at least {{control.errors?.['minlength']['requiredLength']}} characters long</div>
<div *ngIf="control?.errors?.['password']">{{label}} must be at least 8 characters and contain one uppercase character, one lowercase character, one number, and one symbol</div>
<div *ngIf="control?.errors?.['phone']">{{label}} is not a valid phone number</div>
<div *ngIf="control?.errors?.['nameOrCompany']">Name or company is required</div>
<div *ngIf="control?.errors?.['wholeNumber']">{{label}} must be a whole number</div>
