<ng-container *ngIf="isLoading$ | async">
  <span class="indicator-progress" [style.display]="'block'">
    {{ loadingText }}
    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </span>
</ng-container>

<ng-container *ngIf="!(isLoading$ | async)">
  <span class="indicator-label"><ng-content></ng-content></span>
</ng-container>
