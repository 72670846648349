import { AfterContentInit, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IToast } from './toast.model';

@Component({
  selector: 'app-toast',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="d-flex">
      <div class="toast-body">
        <ng-content></ng-content>
      </div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" aria-label="Close" (click)="hide()"></button>
    </div>
  `
})
export class ToastComponent implements AfterContentInit {

  private autoHideDelay = 5000;
  private animationDelay = 150;
  private showToast: boolean;
  private timeoutId: any;

  @Input() toast: IToast;

  @Output() hidden = new EventEmitter<void>();

  @HostBinding('attr.role') role = 'alert';
  @HostBinding('attr.aria-live') ariaLive = 'assertive';
  @HostBinding('attr.aria-atomic') ariaAtomic = 'true';
  @HostBinding('class') get class(): string {
    return `d-block toast align-items-center text-white bg-${this.toast.style} border-0 fade`;
  }
  @HostBinding('class.show') get showClass(): boolean { return this.showToast; }

  private clearTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  private init() {
    this.timeoutId = setTimeout(() => this.hide(), this.autoHideDelay);
  }

  ngAfterContentInit() {
    this.init();
    this.show();
  }

  hide() {
    this.clearTimeout();
    this.showToast = false;
    setTimeout(() => { this.hidden.emit(null); }, this.animationDelay);
  }

  show() {
    this.showToast = true;
  }

}

