import { Injectable } from '@angular/core';
import { IToast } from './toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {

  toasts: IToast[] = [];

  error(message: string): void {
    this.show('danger', message);
  }

  info(message: string): void {
    this.show('info', message);
  }

  remove(toast: IToast): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  show(style: string, message: string): void {
    this.toasts.push({
      style,
      message,
    });
  }

  success(message: string): void {
    this.show('success', message);
  }

  warning(message: string): void {
    this.show('warning', message);
  }
}
