import { HttpParams } from '@angular/common/http';

export function convertObjectToHttpParams(obj: any): HttpParams {
  return Object.keys(obj).reduce((params, key) => {
    const value = obj[key];
    return value !== undefined && value !== null
      ? params.set(key, value.toString())
      : params;
  }, new HttpParams());
}
