import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone
  ) { }

  private static YEAR_MS: number = 1000 * 60 * 60 * 24 * 7 * 4 * 12;

  private static MAPPER: any = [
    { single: 'last year', many: 'years', div: 1 },
    { single: 'last month', many: 'months', div: 12 },
    { single: 'last week', many: 'weeks', div: 4 },
    { single: 'yesterday', many: 'days', div: 7 },
    { single: 'an hour ago', many: 'hours', div: 24 },
    { single: 'just now', many: 'minutes', div: 60 },
  ];

  private _timer: number;

  private getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;

    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

  private removeTimer(): void {
    if (this._timer) {
      window.clearTimeout(this._timer);
      this._timer = null;
    }
  }

  private setupTime(inputDate: any): void {
    const d = new Date(inputDate);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this._timer = this._ngZone.runOutsideAngular(() => {
      if (!!window) {
        return window.setTimeout(() => {
          this._changeDetectorRef.markForCheck();
        }, timeToUpdate);
      }
      return null;
    });

  }

  /**
   * @param inputDate: Date | Moment - not included as TypeScript interface,
   * in order to keep `ngx-pipes` "pure" from dependencies!
   */
  public transform(inputDate: any): string {
    if (!inputDate || (!inputDate.getTime && !inputDate.toDate)) {
      return 'Invalid date';
    }

    this.removeTimer();
    this.setupTime(inputDate);

    const now = +new Date();
    const past = inputDate.toDate ? inputDate.toDate() : inputDate.getTime();

    if (past > now) {
      return 'in the future';
    }

    for (let i = 0, l = TimeAgoPipe.MAPPER.length, ms = now - past, div = TimeAgoPipe.YEAR_MS; i < l; ++i) {
      const elm = TimeAgoPipe.MAPPER[i];
      const unit = Math.floor(ms / (div /= elm.div));

      if (unit >= 1) {
        return unit === 1 ? elm.single : `${unit} ${elm.many} ago`;
      }
    }

    return 'just now';
  }

  public ngOnDestroy(): void {
    this.removeTimer();
  }
}
