import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'invalid-feedback',
  templateUrl: './invalid-feedback.component.html',
  host: { class: 'invalid-feedback' }
})
export class InvalidFeedbackComponent {

  @Input() control: AbstractControl;
  @Input() label: string = '';

  @HostBinding('class.d-block') get invalid() { return this.control?.touched && this.control?.invalid; }

}
