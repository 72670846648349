import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaginatedListBase } from '../../models/paginated-list.model';

const pagesToDisplay = 6;

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent implements OnChanges {

  private readonly _pages$ = new BehaviorSubject<number[]>([]);

  public readonly pages$ = this._pages$.asObservable();

  @Input() data?: PaginatedListBase | null;
  @Input() pageSize = 25;

  @Output() goTo = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  private _setPageRange() {
    if (!this.data) { return; }

    this.pageSize = this.data.pageSize;

    let firstPage = this.data.pageNo - Math.floor(pagesToDisplay / 2);
    if (firstPage < 1) { firstPage = 1; }

    let lastPage = firstPage + pagesToDisplay;
    if (lastPage > this.data.totalPages) { lastPage = this.data.totalPages; }

    const pages = Array(lastPage - firstPage + 1).fill(0).map((_, i) => i + firstPage);
    this._pages$.next(pages);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['data']) {
      this._setPageRange();
    }
  }

  onGoTo(page: number) {
    if (!this.data) { return; }
    if (page < 1) { return; }
    if (page > this.data.totalPages) { return; }
    this.goTo.emit(page);
  }

  onNext() {
    if (!this.data?.hasNextPage) { return; }
    this.onGoTo(this.data.pageNo + 1);
  }

  onPageSizeChange(pageSize: number) {
    if (pageSize === this.pageSize) { return; }
    this.pageSize = pageSize;
    this.pageSizeChange.emit(pageSize);
  }

  onPrevious() {
    if (!this.data?.hasPreviousPage) { return; }
    this.onGoTo(this.data.pageNo - 1);
  }
}
