import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[sortHeader]',
  host: {
    class: 'cursor-pointer'
  }
})
export class SortHeaderDirective {

  @Input() sortHeader!: string;
  @Input() sortOrder?: string | null;

  @Output() sort = new EventEmitter<string>();

  @HostBinding('class.table-sort-asc') get isAsc() {
    return this.sortOrder === this.sortHeader;
  }

  @HostBinding('class.table-sort-desc') get isDesc() {
    return this.sortOrder === `${this.sortHeader}_desc`;
  }

  @HostListener('click') onClick() {
    if (this.sortOrder !== this.sortHeader) {
      this.sort.emit(this.sortHeader);
    } else {
      this.sort.emit(`${this.sortHeader}_desc`);
    }
  }
}
