import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastContainerComponent } from './toast-container.component';
import { ToastComponent } from './toast.component';

export { ToastContainerComponent } from './toast-container.component';
export { ToastService } from './toast.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ToastComponent,
    ToastContainerComponent
  ],
  exports: [
    ToastContainerComponent
  ]
})
export class ToastModule { }
