<!--begin::Header -->
<div class="modal-header">
  <h3 class="modal-title">{{ headerTitle }}</h3>
</div>
<!--begin::Header -->

<!--begin::Body-->
<div class="modal-body text-center">
  <div *ngIf="!!message" class="fs-2">{{ message }}</div>

  <div class="progress my-6 mx-auto w-150px">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" style="width: 100%"></div>
  </div>
</div>
<!--end::Body-->
